<template>
<default-container>
    <q-card slot="page-content" class="container col-md-6 p-0 mb-3 mb-md-4">
        <b-row class="full-width">
            <b-col sm="12" md="12" class="p-3 p-md-4">
                <q-item class="full-width p-0 mb-2 bg-white">
                    <q-item-section>
                        <div class="text-h6">{{ translate('change_of_password') }}</div>
                    </q-item-section>
                </q-item>
                <q-separator class="mb-4" />
                <b-form name="submit" @submit.prevent="resetPassword">
                    <b-input-group class="mb-3">
                        <b-input-group-prepend>
                            <b-input-group-text>
                                <i class="icon-lock"></i>
                            </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input v-model="newUser.current_password" type="password" :class="{ 'is-invalid': submitted && $v.newUser.current_password.$error }" :placeholder="translate('current_password')" autocomplete="current-password" />
                        <div v-if="submitted && $v.newUser.current_password.$error" class="invalid-feedback">
                            <span v-if="!$v.newUser.current_password.required">Current password is required</span>
                        </div>
                    </b-input-group>
                    <b-input-group class="mb-3">
                        <b-input-group-prepend>
                            <b-input-group-text>
                                <i class="icon-lock"></i>
                            </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input v-model="newUser.password" type="password" :class="{ 'is-invalid': submitted && $v.newUser.password.$error }" :placeholder="translate('new_password')" autocomplete="new-password" />
                        <div v-if="submitted && $v.newUser.password.$error" class="invalid-feedback">
                            <span v-if="!$v.newUser.password.required">New password is required</span>
                        </div>
                    </b-input-group>
                    <b-input-group class="mb-3">
                        <b-input-group-prepend>
                            <b-input-group-text>
                                <i class="icon-lock"></i>
                            </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input v-model="newUser.password_confirmation" type="password" :class="{ 'is-invalid': submitted && $v.newUser.password_confirmation.$error }" :placeholder="translate('confirm_password')" autocomplete="current-password" />
                        <div v-if="submitted && $v.newUser.password_confirmation.$error" class="invalid-feedback">
                            <span v-if="!$v.newUser.password_confirmation.required">Confirm Password is required</span>
                            <span v-else-if="!$v.newUser.password_confirmation.sameAsPassword">Passwords must match</span>
                        </div>
                    </b-input-group>
                    
                    <q-btn v-if="!$q.screen.lt.sm" @click="resetPassword" type="button" no-caps color="primary">
                        <i class="fas fa-key mr-1"></i> {{ translate('change_password') }}
                    </q-btn>
                </b-form>
            </b-col>
        </b-row>
    </q-card>
</default-container>
</template>

<script>
import DefaultContainer from '../../containers/DefaultContainer'
import {
    required,
    email,
    minLength,
    sameAs
} from "vuelidate/lib/validators";

export default {
    name: 'ChangePassword',
    components: {
        'default-container': DefaultContainer
    },
    data() {
        return {
            newUser: {
                current_password: null,
                password: null,
                password_confirmation: null
            },
            msg: null,
            submit: 'no',
            button: false,
            submitted: false,
        }
    },
    validations: {
        newUser: {
            current_password: {
                required
            },
            password: {
                required
            },
            password_confirmation: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    },
    methods: {
        resetPassword: function () {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            var params = {
                old_password: this.newUser.current_password,
                new_password: this.newUser.password
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.post(baseUrl + 'auth/change-password', params, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'change_password_' + response.data.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        if (this.submit === 'no') {
                            this.submit = 'yes'
                        }
                    } else {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    // var msg = error.response.data.msg;
                    // this.$toasted.success(msg, {
                    //     duration: this.$toasted_duration
                    // })
                })
        }
    }
}
</script>

<style scoped>
.header-lang {
    border-radius: 0.25rem
}
</style>
